/* FONT AWESOME*/

.fa, .fab, .fal, .far, .fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1
}
.fa-2x {
  font-size: 2em
}
.fa-facebook:before {
  content: "\f09a"
}
.fa-facebook-f:before {
  content: "\f39e"
}
.fa-sign:before {
  content: "\f4d9"
}
.fa-sign-in-alt:before {
  content: "\f2f6"
}
.fa-sign-out-alt:before {
  content: "\f2f5"
}
@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: normal;
  src: url(../fonts/fa-brands-400.eot);
  src: url(../fonts/fa-brands-400.eot?#iefix) format("embedded-opentype"), url(../fonts/fa-brands-400.woff2) format("woff2"), url(../fonts/fa-brands-400.woff) format("woff"), url(../fonts/fa-brands-400.ttf) format("truetype"), url(../fonts/fa-brands-400.svg#fontawesome) format("svg")
}
.fab {
  font-family: "Font Awesome 5 Brands"
}
.far {
  font-weight: 400
}
@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 900;
  src: url(../fonts/fa-solid-900.eot);
  src: url(../fonts/fa-solid-900.eot?#iefix) format("embedded-opentype"), url(../fonts/fa-solid-900.woff2) format("woff2"), url(../fonts/fa-solid-900.woff) format("woff"), url(../fonts/fa-solid-900.ttf) format("truetype"), url(../fonts/fa-solid-900.svg#fontawesome) format("svg")
}
.fa, .far, .fas {
  font-family: "Font Awesome 5 Free"
}
.fa, .fas {
  font-weight: 900
}

/* DIVERSE */

body {
  font-family: Roboto, sans-serif;
}
#main {
  padding-left: 300px;
  max-width: 1200px;
}
strong, .strong {
  font-weight: 700;
}
a {
  cursor: pointer;
}
.below-navigation {
  padding-left: 8px;
  padding-right: 8px;
}
.collapsible .collapsible-header {
  padding-left: 72px;
}

/* TOPNAV */

.burgermargin {
  margin-left: 18px;
  width: 30px;
}
nav i, nav[class^="mdi-"], nav[class*="mdi-"], nav i.material-icons {
  display: inline;
}
nav {
  background-color: white;
  min-height: 71px;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: 100%;
  min-height: 71px;
}
.logo {
  position: absolute;
  top: 14px;
  left: 52px;
  margin-right: 24px;
}

/* SEARCH-BAR */

.search-icon {
  position: absolute;
  left: -16px;
  top: -48px;
  z-index: 2;
}
nav .card {
  margin-top: 1rem;
}
.input-field {
  padding-left: 40px;
  margin-top: 2px;
  margin-left: 00px;
}

.input-field > label {
  pointer-events: none;
}

.input-field>label:not(.label-icon).active {
  -webkit-transform: translateY(-44px) scale(0.5);
  transform: translateY(-44px) scale(0.5);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.input-field>label {
  -webkit-transition: color .2s linear, -webkit-transform .2s linear;
  transition: color .2s linear, -webkit-transform .2s linear;
  transition: transform .2s linear, color .2s linear;
  transition: transform .2s linear, color .2s linear, -webkit-transform .2s linear;
}



/* FIL-INPUT-KNAP */

.file-field .file-path-wrapper {
	padding-top: 22px;
}


/* AUTOCOMPLETE */

.input-field .autocomplete-content {
  width: calc(100% + 50px) !important;
  left: 0px !important;
  top: 38px !important;
}
.input-field .autocomplete-content .circle {
  border-radius: 0%;
}
.autocomplete-content li {
  overflow: hidden;
  height: 48px;
}
.autocomplete-content li img {
  height: auto;
  width: 32px;
}
.input-field .autocomplete-content li .highlight {
  color: black !important;
}

/* TAB-NAVIGATION */

span.badge.new {
  text-transform: none;
  margin-top: 12px;
  font-weight: 700;
  background-color: white;
}
span.badge {
  margin-left: 0px;
}

a.active span.badge.new,
.main-wrapper.main-wrapper--publishing-house a.active span.badge.new {
  color: white;
}
.tabs .tab a {
  font-weight: 700;
  padding: 0 12px;
}
.tabs .indicator {
  background-color: white;
  height: 5px;
  bottom: -4;
}
.tabs .tab {
  border-right: 2px solid #fafafa;
}
.tabs .tab a {
  color: white;
}
.tab a.active {
  color: black;
}
.tabs .indicator {
  display: none;
}
.tabs .tab a.active, .tabs .tab a:focus, .tabs .tab a:focus.active {
  color: black;
}
.tab a:not(.active):hover {
  color: white;
  opacity: 0.8;
}
.tabs .faqtab {
  max-width: 36px;
  border-right: none;
}
.tabs .tab a i {
position: relative;
top: 7px;
}

/* FAQ-TAB */

#faq .collapsible-header {
  font-size: 18px;

  padding: 1.25rem;
  line-height: 28px;
  padding-left: 20px;
}
#faq .collapsible-body {
  padding-left: 20px;
}

/* SIDENAV */

.sidenav {
  padding-top: 0px;
  margin-top: 71px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.sidenav .collapsible-header {
  line-height: 48px;
  padding: 0 16px;
}
.sidenav .collapsible li {
  overflow: visible;
}
.sidenav .collapsible-header i {
  width: 2rem;
  font-size: 1.6rem;
  display: inline-block;
  text-align: center;
  margin-right: .75rem;
}
.sidenav .collapsible {
  margin: 0;
}
.sidenav li>a>i {
  margin-right: 16px;
}
.sidenav .collapsible-header i {
  font-size: 1.4rem !important;
}
.sidenav li>a>i.material-icons {
  float: left;
  height: 46px;
  line-height: 48px;
  margin: 0 16px 0 0;
  font-size: 1.6rem !important;
}
.sidenav .collapsible-body ul {
  margin-left: 56px;
}
.sidenav li {
  line-height: 40px;
}
.category-callout {
  margin-right: -32px;
  margin-top: -42px;
  display: block;
  float: right;
}
.sidenav .collapsible .collapsible {
  margin-left: -56px;
}
li.active > .category-callout {
  -ms-transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
  margin-right: -28px;
  margin-top: -52px;
}
.sidenav .badge.new,
.main-wrapper.main-wrapper--publishing-house .sidenav .badge.new {
  color: white;
}
.login, .date-interval {
  margin-left: -54px;
  line-height: 40px !important;
  height: 40px !important;
}
.logout, .date-interval {
  padding-left: 50px !important;
}
.date-interval {
  font-weight: 400 !important;
  font-size: 15px !important;
}
.date-interval i {
  padding-left: 2px;
  line-height: 40px !important;
}
.login-info {
  line-height: 24px !important;
}

/* RADIO-BUTTONS LAVES TIL AT LIGNE CHECKBOXES */

[type="radio"]:not(:checked)+span:before, [type="radio"]:not(:checked)+span:after, [type="radio"]:checked+span:before, [type="radio"]:checked+span:after {
  border-radius: 2px;
}
[type="radio"]:checked+span:after, [type="radio"].with-gap:checked+span:before, [type="radio"].with-gap:checked+span:after {
  border: 2px solid white;
}
[type="radio"]:not(:checked)+span, [type="radio"]:checked+span {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  font-size: 1rem;
  -webkit-transition: .28s ease;
  transition: .28s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
[type="radio"]+span:before, [type="radio"]+span:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  margin: 0px;
  width: 20px;
  height: 20px;
  z-index: 0;
  border-radius: 1px;
  margin-top: 3px;
  -webkit-transition: .2s;
  transition: .2s;
  top: -3px;
}
[type="radio"]+span:after {
  border: 2px solid white;
}
[type="radio"]:checked+span:after {
  top: 0;
  left: 1px;
  width: 8px;
  height: 13px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  -webkit-transform: rotateZ(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
[type="radio"]:not(:checked)+span:before, [type="radio"]:not(:checked)+span:after {
  border: 2px solid #5a5a5a;
  background-color: transparent;
}
[type="radio"]:checked, [type="radio"]:checked+span:after {
  background-color: transparent;
}
[type="radio"]:not(:checked), [type="radio"]:checked {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
[type="checkbox"], [type="radio"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

/* INDETERMINATE CHECKBOX */

[type="checkbox"].filled-in.indeterminate + span:not(.lever):before {
  top: 1px;
  left: -8px;
  width: 12px;
  height: 12px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
[type="checkbox"].filled-in+span:not(.lever):before, [type="checkbox"].filled-in+span:not(.lever):after {
  -webkit-transition: none;
  transition: none;
}

/* SEKTIONS-OVERSKRIFT OG DROPDOWN */

.section-headline, .no-results-headline {
  margin:36px 0 14px 24px;
  color:#757575;
  font-size:1rem;
  width:calc(100% - 24px)
}
.no-results-headline {
  margin:34px 0 14px 24px;
}
.dropdown-content li>a>i {
  margin: 0 12px 0 0;
}
label, .dropdown-date {
  font-size: 15px;
  color: black;
}

.dropdown-content > li > label {
  cursor: pointer;
  font-size: 16px;
  display: block;
  line-height: 22px;
  padding: 14px 16px;
}

/* STJERNEMARKEREDE */

.sidenav .collapsible-header.highlighted,
.main-wrapper.main-wrapper--publishing-house .sidenav .collapsible-header.highlighted {
  color: white;
}
.main-wrapper.main-wrapper--publishing-house .sidenav .collapsible-header.highlighted .badge {
  background-color: white;
}
.sidenav .collapsible-header.highlighted .material-icons,
.main-wrapper.main-wrapper--publishing-house .sidenav .collapsible-header.highlighted .material-icons {
  color: white;
}

/* NEWS */

li:not(.active) .collapsible-header:hover {
  background-color: #fcfefe;
}
.collapsible li, .keyboard-focused .collapsible-header:focus {
  background-color: #fff
}
.headline3 {
  color: #bdbdbd
}
#newsfaq, .collapsible.#newsfaq {
  box-shadow: none
}
.collapsible-header {
  line-height: 1.4rem;
  display: inherit;
  position: relative;
  padding-bottom: 1.5rem;
  transition-property: border-bottom;
  transition-delay: .3s;
  border-bottom: 1px solid rgba(160, 160, 160, .2);
}
.news-cover {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 140px;
  margin-left: 20px
}
.news-cover-shadow {
  height: 30px;
  width: 0
}
li:not(.active) .news-cover-shadow {
  position: absolute;
  bottom: -2px;
  right: 16px;
  margin-left: 20px;
  transition-property: width;
  transition-delay: .3s;
  width: 140px
}
.collapsible li {
  overflow: hidden
}
.headline1, .headline2, .headline3 {
  font-size: 13px;
  font-weight: 500
}
.headline3 {
  font-size: 11px;
}
:not(.no-cover) .headline2 {
  width: calc(100% - 186px)
}
:not(.no-cover) .bog-review-quote {
  width: calc(100% - 160px)
}
.only-headline .headline3 {
  padding-bottom: 0
}
.collapsible-header i {
  width: 0;
  font-size: 14px!important;
  margin-right: 14px
}
.collapsible-header p {
  margin: 0
}
.media-icon {
  box-sizing: border-box;
  color: #fff;
  display: block;
  float: left;
  font-size: 22px;
  font-weight: 500;
  height: 44px;
  line-height: 32px;
  padding-top: 6px;
  text-align: center;
  text-size-adjust: 100%;
  width: 44px;
  position: absolute;
  margin-left: -54px;
  border-radius: 50%
}
.blog-icon {
  color: #000;
  border: 1px solid #000
}
li.active .collapsible-header {
  border-bottom: none
}
.bog-review-quote, .collapsible-header .bog-review-quote {
  clear: both;
  position: relative;
  margin-left: -38px;
  font-weight: 500;
  line-height: 110%!important;
  font-size: 22px;
  width: 80%
}
.bog-review-quote:after, .collapsible-header .bog-review-quote:after {
  content: "";
  width: 55px;
  height: 40px;
  background: url(https://d1ixebpu9pg2je.cloudfront.net/assets/images/blockquote-aa738e65f95ac87e5866641595687773.png) no-repeat;
  position: absolute;
  left: 0;
  top: -20px;
  margin-left: -18px
}
.bog-review-edit-quote{
	position: relative
}
.bog-review-edit-quote:after {
	content: "";
	width: 55px;
	height: 40px;
	background: url(https://d1ixebpu9pg2je.cloudfront.net/assets/images/blockquote-aa738e65f95ac87e5866641595687773.png) no-repeat;
	position: absolute;
	left: 10px;
	top: 40px;
}
.bog-review-edit-quote textarea.materialize-textarea {
	font-size: 22px;
    font-weight: 500;
}
.bog-review-quote {
  margin-left: 18px;
  margin-bottom: 44px
}
.collapsible-body .bog-review-quote:last-of-type {
  margin-bottom: 30px
}
.collapsible-body {
  background-color: #fff;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 18px;
}
.reviews-list .collapsible-body {
	padding-bottom: 18px;
}
.reviews-list .collapsible-header {
	 
}
.secondary-content {
  position: absolute;
  top: 16px;
  right: 188px;
  color: rgba(0, 0, 0, .87);
  cursor: pointer
}
.byline, .extract {
  font-weight: 500;
  color: #bdbdbd
}
.card .secondary-content {
  right: 176px
}
.no-cover .secondary-content {
  right: 32px
}
.secondary-content .material-icons {
  font-size: 26px!important
}
.article-container {
  width: calc(100% - 130px);
  font-size: 14px
}
.no-cover .article-container {
  width: 100%;
  font-size: 14px
}
.article-container p {
  width: calc(100% - 20px);
  margin: 0
}
.collapsible-header .article-container p:first-child {
  font-weight: 500;
  font-size: 22px;
  line-height: 125%!important;
  margin: 0
}
.collapsible-body .article-container p:first-child {
  font-weight: 700;
  margin: 0
}
.collapsible-header .article-container {
  margin-left: -54px
}
.collapsible-body .article-container {
  margin-left: 0
}
.byline {
  font-size: 11px;
  margin-top: 6px
}
.btn, .btn-flat {
  height: 30px;
  line-height: 30px;
  text-transform: none;
  margin-bottom: 12px;
  margin-right: 6px
}
.action-more, .action-more-disabled {
  display: block;
  clear: both;
  border-top: 1px solid rgba(160, 160, 160, .2);
  padding: 11px 20px 12px;
  margin-right: -30px;
  margin-left: -18px;
}

.action-more::after, .action-more-disabled::after {
  content: "";
  clear: both;
  display: table;
}

.action-more .facebook-link {
  font-size: 120%
}
.btn-flat {
  margin-bottom: 3px
}
.action-more {
  line-height: 1.5;
  font-weight: 500;
  text-transform: uppercase
}
.action-more a, .action-more-disabled {
  font-size: 110%;
  font-weight: 500;
  text-transform: uppercase
}
.action-more-disabled {
  color: #bdbdbd !important;
}
.versals {
  text-transform: uppercase
}
.news-links {
  margin-left: 0;
  margin-top: 1.5rem;
  padding-bottom: 1rem;
  width: calc(100% - 140px)
}
.no-cover .news-links {
  width: 100%;
}
.news-links:first-child {
  margin-top: 0
}
.news-image-container {
  max-height: 380px;
  margin-left: -18px;
  margin-right: -30px;
  overflow: hidden
}
.img-middle {
  position: relative;
  top: 50%;
  transform: perspective(1px) translateY(-50%);
}
.img-bottom {
  position: relative;
  top: 70%;
  transform: perspective(1px) translateY(-70%);
}
.image-title {
  font-size: 11px;
  letter-spacing: 1px;
  opacity: .85;
  position: absolute;
  padding: 6px 10px 4px;
  z-index: 100
}
.bog-review-quote+.news-image-container, .news-image-container:first-child {
  margin-bottom: 1.5rem
}
.article-container+.news-image-container {
  margin-top: 1.5rem
}
.news-image-container img {
  width: 100%
}
.collapsible-header .article-container, .collapsible-header .bog-review-quote {
  margin-top: 30px
}
.action-more .material-icons {
  vertical-align: -10%
}
.faq-toggle {
  margin-right: -36px
}
#newsfaq {
  border: none
}
#newsfaq .collapsible-header {
  display: none;
  border-top: none
}
#newsfaq .collapsible-body {
  width: 100%;
  border-bottom: none;
  padding: 0
}
#newsfaq .card {
  margin: 0
}
#newsfaq li {
  overflow: visible
}

.action-more a {
	margin-right: 24px;
	display: inline;
}

/* SEARCHBAR */

input#search-auto {
  border-bottom: 0px white;
  box-shadow: 0 0px 0 0 white;
  position: inherit;
  left: 0px;
  top: 2px;
  margin-left: 6px;
}
.searchbar {
  margin-left: 312px;
  width: calc(100% - 360px);
  max-width: 852px;
  background-color: white;
  
}

input:not([type]), input[type=text]:not(.browser-default), input[type=password]:not(.browser-default), input[type=email]:not(.browser-default), input[type=url]:not(.browser-default), input[type=time]:not(.browser-default), input[type=date]:not(.browser-default), input[type=datetime]:not(.browser-default), input[type=datetime-local]:not(.browser-default), input[type=tel]:not(.browser-default), input[type=number]:not(.browser-default), input[type=search]:not(.browser-default), textarea.materialize-textarea {
  font-size: 16px;
  margin: 0 0 0 0;
  height: 100%;
}
.review-search-bar-container {
	position: relative;
}
.search-bar-caret {
	display: block;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 0;
}
.review-search-bar {
	margin-left: 0;
	width: 100%;
 	border-top: none;
    border-bottom: 1px solid #2d88b5;
    border-left: none;
}
.review-search-bar .input-field.chips {
	padding-left: 0;
	margin-bottom: 0;
}

.input-field > .search-label {
	font-size: 16px;
	line-height: 1.4;
	transition: transform .2s linear,color .2s linear;
}

.input-field > .search-label.focused {
	transform: translateY(-20px) scale(.65);
}

@media only screen and (max-width: 992px) {
  .searchbar {
    margin-left: 50px;
    width: calc(100% - 80px);
    max-width: 800px;
  }

  .review-search-bar {
	margin-left: 0;
	width: 100%;
	}
}



/* CHIPS */

.chip {
  display: inline-block;
  height: 26px;
  font-size: 11px;
  font-weight: 500;
  color: white;
  line-height: 26px;
  padding: 0 12px;
  border-radius: 2px;
  margin-bottom: 0px;
  margin-right: 5px;
  margin-top: 5px;
}
.chips.focus, .chips {
  border-bottom: none;
}
.chips, .chips.focus {
  box-shadow: none;
  -webkit-box-shadow: none;
  min-height: 40px;
  line-height: 38px;
}
.chip .close {
  cursor: pointer;
  float: right;
  font-size: 14px;
  line-height: 26px;
  padding-left: 6px;
}
.input-field.chips {
  width: calc(100% - 50px);
  padding-left: 12px;
  top: 0px;
  margin-top: 0px;
  margin-left: 00px;
}
.chips .autocomplete-content {
  margin-top: 20 !important;
  margin-bottom: 0;
  z-index: 10000;
  position: absolute;
}
.modal.allow-overflow {
	overflow: visible;
}
/* SØGEKNAPPER */

.searchbutton {
  position: absolute;
  font-weight: 700;
  font-size: 14px;
  text-transform: none;
  height: 36px;
  line-height: 36px;
  top: 2px;
  right: -54px;
  -webkit-box-shadow: none;
  box-shadow: none;
  z-index: 12;
}
.searchbutton i.material-icons {
  line-height: 36px;
  font-size: 20px;
}
.btn.searchbutton:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.deletebutton, .savebutton {
  position: absolute;
  height: 36px;
  bottom: -2px;
  right: -62px;
  -webkit-box-shadow: none;
  box-shadow: none;
  z-index: 10;
}
.deletebutton i.material-icons, .savebutton i.material-icons {
  line-height: 36px;
  font-size: 30px;
}
.savebutton {
  right: 12px;
}

/* POPULAR TITLES */

.card-stacked .card .card-content {
  padding: 20px;
}
.cover-horizontal {
  width: 90%;
}
.card.horizontal .card-image img {
  width: 50%!important
}
.card.horizontal .card-image {
  max-width: 25%;
}
.book-cover-list {
  object-fit: cover;
  background-size: cover;
  width: 100%;
  height: 254px;
  object-position: 0 0;
  cursor: pointer;
}
.cat-title {
  top: 0;
  left: 0;
  font-size: 11px;
  letter-spacing: 1px;
  padding: 6px 10px 4px;
  opacity: 0.85;
  position: absolute;
  text-transform: uppercase;
  object-fit: cover;
  background-size: cover;
  width: 100%;
  object-position: 0 0;
}
.horizontal {
  margin-left: 24px;
  margin-right: 24px;
}
.card .action-more {
  margin-left: 0px;
  width: 100%;
}
.card-content {
  display: block;
  height: 100%;
  overflow: hidden;
}
.card-text-horizontal {
  width: calc(100% - 172px);
  display: inline-block;
}
.pop-score {
  display: block;
  width: 160px;
  margin-top:-24px;
  margin-bottom: -24px;
  margin-right: -24px;
}
.minor-pop-score {
  height: 44px;
  width: 160px;
  background-color: white;
  padding-left: 12px;
  font-size: 12px;
  line-height: 12px;
  -webkit-box-shadow: inset 4px -4px 4px -2px rgba(0,0,0,0.14);
  -moz-box-shadow: inset 4px -4px 4px -2px rgba(0,0,0,0.14);
  box-shadow: inset 4px -4px 4px -2px rgba(0,0,0,0.14);
}
.major-pop-score {
  color: white;
  height: 128px;
  width: 160px;
  padding-left: 12px;
  font-size: 14px;
  line-height: 14px;
}
.major-pop-score--clickable {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .major-pop-score {
    pointer-events: none;
  }
}

.no-score {
  padding-top: 40px;
  height: 200px;
}
.meta-pop-score, .meta-score {
  letter-spacing: 2px;
  font-size: 32px;
  font-weight: 400;
  display: block;
  padding-top: 34px;
}

.meta-pop-score.meta-pop-score--high {
  letter-spacing: 0px;
}

.small-text {
  font-size: 12px;
  font-weight: 400;
  padding-top: 12px;
  display: block;
}
.line-break {
  width: 100%;
}
.book-title {
  font-size: 18px;
  font-weight: 400;
}
.book-author {
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 22px;
}
.book-info {
  font-size: 14px;
}
td, th {
  padding: 5px 5px;
  padding-left: 0px;
  font-size: 11px;
  font-weight: 400;
}
tr:last-child {
  border-bottom: none;
}
.toned {
  opacity: .5;
  letter-spacing: 0px;
  font-size: 22px;
}

.card-reveal .material-icons {
  color: #349F7E;
}
.card .card-title {
  font-size: 18px;
  font-weight: 400;
}
.small-metascore {
  color: white;
  padding: 6px;
  margin-right: 12px;
  border-radius: 3px;
}
.card .card-content {
  padding-bottom: 6px;
}

/* COLLAPSIBLE PUBLICATIONS */

.thin {
  display: none;
}
.publications .collapsible li {
  overflow: visible;
  background-color: transparent;
}
.publications .collapsible-body {
  border-style: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  display: none;
  border-bottom: initial;
  -webkit-box-sizing: initial;
  box-sizing: initial;
  padding: 0rem;
  background-color: transparent;
}
.publications .collapsible.popout>li.active {
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 16px 0;
  border: none;
}
.publications .collapsible {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* PUBLICATION-CARDS */

.publication-cover-container {
  float: right;
}
.publication-cover {
  width: auto;
  width: 160px;
  cursor: zoom-in;
}
.card-panel {
  padding: 16px 24px;
  margin-top: 0;
  overflow: hidden;
}
.publications .title {
  font-weight: 700;
}
.card-panel .secondary-content {
  position: relative;
  top: 4px;
  right: 18px;
}
.graytone {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

/* MEDIA-QUERIES */

.sidenav {
  transition: transform 0.25s ease-out;
}

#main .sidenav-overlay {
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
  display: block;
}

#main .sidenav-overlay-enter-active,
#main .sidenav-overlay-enter-done,
#main .sidenav-overlay-exit {
  opacity: 1;
}

#main .sidenav-overlay-exit-active {
  opacity: 0;
}

@media only screen and (min-width: 993px) {
  .sidenav {
    height: calc(100% - 70px);
  }

  #main.main--desktop-sidebar-hidden .sidenav {
    display: none;
  }

  #main.main--desktop-sidebar-hidden {
    padding-left: 0;
  }
}

@media only screen and (max-width: 992px) {
  .sidenav {
    margin-top: 0px;
    padding-top: 72px;
    z-index: 1000;
  }

  #main {
    padding-left: 0;
  }

  #slide-out {
    display: block;
  }

  #main.main--mobile-sidebar-visible .sidenav {
    transform: translateX(0);
  }
}

@media only screen and (max-width: 600px) {
  .pop-score {
    display: block;
    width: 110px;
    margin-top: -24px;
    margin-bottom: -24px;
    margin-right: -34px;
  }
  .minor-pop-score {
    width: 100px;
    display: none;
  }
  .major-pop-score {
    width: 100px;
  }
  .book-cover-list {
    height: 172px;
  }
  .edition-date, .editions, .cat-title {
    display: none;
  }
  .card-text-horizontal {
    width: calc(100% - 80px);
  }
  .card-content .secondary-content {
    right: 16px;
    top: 136px;
  }
  .toned {
    font-size: 18px;
  }
  .meta-score {
    font-size: 26px;
  }
  .meta-pop-score {
    font-size: 18px;
  }
  .small-text {
    font-size: 10px;
    padding-top: 6px;
  }
  .book-author {
    padding-bottom: 2px;
  }
  .secondary-content {
    right: 32px;
  }
  .collapsible-header .bog-review-quote, .bog-review-quote {
    width: 100%;
  }
  .article-container, .article-container p {
    width: 100%;
  }
  .collapsible-header .article-container p {
    margin-left: 0px;
    width: calc(100% + 30px);
  }
  .news-links {
    width: 100%;
  }
  :not(.no-cover) .headline2 {
    width: calc(100% - 32px);
  }
  :not(.no-cover) .bog-review-quote {
    width: 100%;
  }
  .collapsible.popout>li {
    margin: 0 0px;
  }
  .horizontal {
    margin-left: 0px;
    margin-right: 0px;
  }
  .section-headline, .no-results-headline {
    margin-left: 0px;
  }
  .dropdown-trigger {
    margin-right: -36px;
  }
}

/* FAB */

.fixed-action-btn {
  z-index:1000;
}

/* INDETERMINATE-RETTELSE TIL FF OG IE */

[type="checkbox"].filled-in.indeterminate + span:not(.lever)::before {
  border: 2px solid black;
  background-color: #349F7E !important;
  top: -1px;
  left: -14px;
  width: 16px;
  height: 16px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
[type="radio"].indeterminate + span::before, [type="checkbox"].filled-in.indeterminate + span:not(.lever)::after {
  border: 2px solid #5a5a5a;
}
[type="radio"].indeterminate,
[type="checkbox"].indeterminate + span:not(.lever)::after {
  border: 2px solid #5a5a5a;
}


/* RETTELSE TIL LIGHTGALLERY */

.lg-sub-html, .lg-toolbar {
  background-color: transparent;
}

/** NICK: Animationer. */

.toast {

}

.toast-animation-enter {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.toast-animation-enter-active,
.toast-animation-enter-done {
  opacity: 1;
  top: 0px;
}

.toast-animation-exit {
  opacity: 1;
  top: 0px;
  transition: all 0.3s ease-out;
}

.toast-animation-exit-active {
  opacity: 0;
  margin-top: -40px;
}



/* STANDARD-FARVER */


.standard-color {
  color: #349F7E;
}
.standard-bg-color,
.react-hint__content {
  background-color: #349F7E;
}

nav .input-field label i, span.badge.new, .sidenav li>a>i, .sidenav li>a>[class^="mdi-"], .sidenav li>a li>a>[class*="mdi-"], .sidenav li>a>i.material-icons, .datepicker-cancel, .datepicker-clear, .datepicker-today, .datepicker-done, .sidenav .collapsible-header.highlighted .badge, input#search-auto, .card-content .secondary-content, .secondary-content, .toggleStar, .dropdown-content li>a, .dropdown-content li>span, .card-reveal .material-icons {
  color: #349F7E;
}
.tabs .tab, a.active span.badge.new, .sidenav .badge.new, .datepicker-date-display, .datepicker-table td.is-selected, .sidenav .collapsible-header.highlighted, .sidenav .collapsible-header.highlighted:hover, .sidenav .collapsible-header.highlighted:focus, .chip, .sidenav .badge.new, [type="radio"]:checked+span:before, [type="checkbox"].filled-in:checked+span:not(.lever):after, .major-pop-score, .modal.chart-modal {
  background-color: #349F7E;
}
.fixed-action-btn .fab-backdrop,
[type="checkbox"].filled-in.indeterminate + span:not(.lever):before {
  background-color: #349F7E !important;
}
.searchbar {
  border-top: 16px solid #349F7E;
  border-bottom: 14px solid #349F7E;
  border-left: 20px solid #349F7E;
}
[type="radio"]:checked+span:before, [type="checkbox"].filled-in:checked+span:not(.lever):after {
  border: 2px solid #349F7E;
}
.light-standard-color, .toast .toast-action {
  color: #6ad1ad;
}

.light-standard-bg-color, .chip:focus, .btn:hover, .btn-large:hover, .btn-small:hover {
  background-color: #6ad1ad;
}

.light-light-standard-color {
  color: #E8F5E9;
}
.light-light-standard-bg-color {
  background-color: #E8F5E9;
}

.popularity-chart .bb-ygrid {
  stroke: #E8F5E9;
}

.popularity-chart .bb-region {
  fill: #349F7E;
}


.material-tooltip {
  background-color: #349F7E;
}

.iframe-close i,
.iframe-menu i,
.standardinput .input-field>label.active,
.bodytextinput .input-field>label:not(.label-icon).active {
  color: #349F7E;
}

#chart .bb-region {
  fill: #349F7E;
}

.select-label {
  color: #349F7E !important;
}

textarea.materialize-textarea {
  border: 1px solid #9e9e9e;
  padding: 16px;
  border-radius: 3px;
  min-height: 140px;
  resize: vertical;
}

.standardinput textarea.materialize-textarea + label,
.standardinput textarea.materialize-textarea + label.active {
  padding-top: 16px;
  left: 16px;
  font-size: 18px;
}

.standardinput textarea.materialize-textarea + label:not(.label-icon).active {
	-webkit-transform: translateY(-20px) scale(0.65);
	transform: translateY(-20px) scale(0.65);
	background-color: white;
	padding-left: 4px;
	padding-right: 4px;
}

textarea.materialize-textarea:focus {
  border: 1px solid #349F7E !important;
}

.select-wrapper input.select-dropdown:focus {
  border-bottom: 2px solid #349F7E !important;
}

.datepicker-day-button:focus {
  background-color: #E8F5E9;
}

[type=checkbox]:checked+span:not(.lever):before {
  border-color: transparent #349F7E #349F7E transparent;
}

input:not([type]):focus:not([readonly]),
input[type=text]:not(.browser-default):focus:not([readonly]),
input[type=password]:not(.browser-default):focus:not([readonly]),
input[type=email]:not(.browser-default):focus:not([readonly]),
input[type=url]:not(.browser-default):focus:not([readonly]),
input[type=time]:not(.browser-default):focus:not([readonly]),
input[type=date]:not(.browser-default):focus:not([readonly]),
input[type=datetime]:not(.browser-default):focus:not([readonly]),
input[type=datetime-local]:not(.browser-default):focus:not([readonly]),
input[type=tel]:not(.browser-default):focus:not([readonly]),
input[type=number]:not(.browser-default):focus:not([readonly]),
input[type=search]:not(.browser-default):focus:not([readonly]),
select:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #349F7E;
  -webkit-box-shadow: 0 1px 0 0 #349F7E;
  box-shadow: 0 1px 0 0 #349F7E;
}

input:not([type]):focus:not([readonly])+label,
input[type=text]:not(.browser-default):focus:not([readonly])+label,
input[type=password]:not(.browser-default):focus:not([readonly])+label,
input[type=email]:not(.browser-default):focus:not([readonly])+label,
input[type=url]:not(.browser-default):focus:not([readonly])+label,
input[type=time]:not(.browser-default):focus:not([readonly])+label,
input[type=date]:not(.browser-default):focus:not([readonly])+label,
input[type=datetime]:not(.browser-default):focus:not([readonly])+label,
input[type=datetime-local]:not(.browser-default):focus:not([readonly])+label,
input[type=tel]:not(.browser-default):focus:not([readonly])+label,
input[type=number]:not(.browser-default):focus:not([readonly])+label,
input[type=search]:not(.browser-default):focus:not([readonly])+label,
select:not(.browser-default):focus:not([readonly])+label,
textarea.materialize-textarea:focus:not([readonly])+label {
  color: #349F7E;
}

input.valid[type=text]:not(.browser-default) {
  border-bottom: 1px solid #349F7E;
  box-shadow: 0 1px 0 0 #349F7E;
}

.main-wrapper.main-wrapper--publishing-house {
  .standard-color {
    color: #2d88b5;
  }
  .standard-bg-color,
  .react-hint__content {
    background-color: #2d88b5;
  }

  nav .input-field label i, span.badge.new, .sidenav li>a>i, .sidenav li>a>[class^="mdi-"], .sidenav li>a li>a>[class*="mdi-"], .sidenav li>a>i.material-icons, .datepicker-cancel, .datepicker-clear, .datepicker-today, .datepicker-done, .sidenav .collapsible-header.highlighted .badge, input#search-auto, .card-content .secondary-content, .secondary-content, .toggleStar, .dropdown-content li>a, .dropdown-content li>span, .card-reveal .material-icons {
    color: #2d88b5;
  }
  .tabs .tab, a.active span.badge.new, .sidenav .badge.new, .datepicker-date-display, .datepicker-table td.is-selected, .sidenav .collapsible-header.highlighted, .sidenav .collapsible-header.highlighted:hover, .sidenav .collapsible-header.highlighted:focus, .chip, .sidenav .badge.new, [type="radio"]:checked+span:before, [type="checkbox"].filled-in:checked+span:not(.lever):after, .major-pop-score, .modal.chart-modal {
    background-color: #2d88b5;
  }
  .fixed-action-btn .fab-backdrop,
  [type="checkbox"].filled-in.indeterminate + span:not(.lever):before {
    background-color: #2d88b5 !important;
  }
  .searchbar {
    border-top: 16px solid #2d88b5;
    border-bottom: 14px solid #2d88b5;
    border-left: 20px solid #2d88b5;
  }
  .review-search-bar {
 	border-top: none;
    border-bottom: 1px solid #2d88b5;
    border-left: none;
  }
  [type="radio"]:checked+span:before, [type="checkbox"].filled-in:checked+span:not(.lever):after {
    border: 2px solid #2d88b5;
  }
  .light-standard-color, .toast .toast-action {
    color: #68b8e7;
  }

  .light-standard-bg-color, .chip:focus, .btn:hover, .btn-large:hover, .btn-small:hover {
    background-color: #68b8e7;
  }

  .light-light-standard-color {
    color: #e1f5fe;
  }
  .light-light-standard-bg-color {
    background-color: #e1f5fe;
  }

  .popularity-chart .bb-ygrid {
    stroke: #e1f5fe;
  }

  .popularity-chart .bb-region {
    fill: #2d88b5;
  }


  .material-tooltip {
    background-color: #2d88b5;
  }

  .iframe-close i,
  .iframe-menu i,
  .standardinput .input-field>label.active,
  .bodytextinput .input-field>label:not(.label-icon).active {
    color: #2d88b5;
  }

  #chart .bb-region {
    fill: #2d88b5;
  }

  .select-label {
    color: #2d88b5 !important;
  }

  textarea.materialize-textarea:focus {
    border: 1px solid #2d88b5 !important;
  }

  .select-wrapper input.select-dropdown:focus {
    border-bottom: 2px solid #2d88b5 !important;
  }

  .datepicker-day-button:focus {
    background-color: #e1f5fe;
  }

/*
[type=checkbox]:checked+span:not(.lever):before {
  border-color: transparent #2d88b5 #2d88b5 transparent;
}
*/

input:not([type]):focus:not([readonly]),
input[type=text]:not(.browser-default):focus:not([readonly]),
input[type=password]:not(.browser-default):focus:not([readonly]),
input[type=email]:not(.browser-default):focus:not([readonly]),
input[type=url]:not(.browser-default):focus:not([readonly]),
input[type=time]:not(.browser-default):focus:not([readonly]),
input[type=date]:not(.browser-default):focus:not([readonly]),
input[type=datetime]:not(.browser-default):focus:not([readonly]),
input[type=datetime-local]:not(.browser-default):focus:not([readonly]),
input[type=tel]:not(.browser-default):focus:not([readonly]),
input[type=number]:not(.browser-default):focus:not([readonly]),
input[type=search]:not(.browser-default):focus:not([readonly]),
select:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #2d88b5;
  -webkit-box-shadow: 0 1px 0 0 #2d88b5;
  box-shadow: 0 1px 0 0 #2d88b5;
}

input:not([type]):focus:not([readonly])+label,
input[type=text]:not(.browser-default):focus:not([readonly])+label,
input[type=password]:not(.browser-default):focus:not([readonly])+label,
input[type=email]:not(.browser-default):focus:not([readonly])+label,
input[type=url]:not(.browser-default):focus:not([readonly])+label,
input[type=time]:not(.browser-default):focus:not([readonly])+label,
input[type=date]:not(.browser-default):focus:not([readonly])+label,
input[type=datetime]:not(.browser-default):focus:not([readonly])+label,
input[type=datetime-local]:not(.browser-default):focus:not([readonly])+label,
input[type=tel]:not(.browser-default):focus:not([readonly])+label,
input[type=number]:not(.browser-default):focus:not([readonly])+label,
input[type=search]:not(.browser-default):focus:not([readonly])+label,
select:not(.browser-default):focus:not([readonly])+label,
textarea.materialize-textarea:focus:not([readonly])+label {
  color: #2d88b5;
}

input.valid[type=text]:not(.browser-default) {
  border-bottom: 1px solid #2d88b5;
  box-shadow: 0 1px 0 0 #2d88b5;
}

}

/* BAGGRUNDSFARVE */

body, ul.tabs, .tabs .tab a.active, .tabs .tab a:focus, .tabs .tab a:focus.active, .sidenav, .sidenav .collapsible-header, .sidenav .collapsible li, .sidenav .collapsible-body, .sidenav li.active, .collapsible, #newsfaq .collapsible-body, .content-override {
  background-color: #fafafa;
}

/* LOGIN/LOGOUT */
.auth-page {
  width: 100vw;
  min-height: 100vh;
  background: url(../images/auth-bg.jpg);
  background-size: cover;
  position: relative;
}

.auth-page small {
  display: block;
}

.auth-page .auth-logo {
  margin-top: 50px;
  margin-bottom: 15px;
}

.auth-page .jumbotron {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  margin: 30px 0;
  padding: 30px 60px;
  background-color: #349f7e;
}

.auth-page .jumbotron.jumbotron-publishing-house {
  background-color: #2d88b5;
}

.auth-page .jumbotron-content {
  width: 67%;
  margin: 0 auto;
  color: #fff;
}

.auth-page h2 {
  margin: 20px 0;
  font-weight: 600;
  font-size: 30px;
  color: #fff;
}

.auth-page p {
  font-size: 21px;
  font-weight: 200;
  margin: 0 0 15px 0;
}

@media (max-width: 992px) {
  .auth-page .jumbotron {
    padding: 30px;
  }

  .auth-page .jumbotron-content {
    width: 100%;
    margin: 0 auto;
    color: #fff;
  }
}

.fullscreen-loader {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.fullscreen-loader .fullscreen-loader-inner {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.fullscreen-loader .progress {
  margin: 0 auto;
  max-width: 200px;
}

.card .card-reveal.publishing-house-description {
  margin-left: 25%;
  width: 75%;
}

.custom-lightbox {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #000;
  z-index: 10000;
  cursor: zoom-out;
  user-select: none;
}

.custom-lightbox .material-icons {
  font-size: 32px;
}

.custom-lightbox-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: zoom-out;
}

.custom-lightbox-actions {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  padding-right: 8px;
}

.custom-lightbox-actions a {
  color: #999;
  display: block;
  padding: 16px 8px;
  transition: color 0.25s ease;
}

.custom-lightbox-actions a:hover {
  color: #fff;
}

.custom-lightbox {
  opacity: 0;
  transition: opacity 0.25s ease;
}

.custom-lightbox-image {
  transform: scale(90%);
  transition: transform 0.25s ease;
}

.custom-lightbox-enter-active,
.custom-lightbox-enter-done {
  opacity: 1;
}

.custom-lightbox-enter-active .custom-lightbox-image,
.custom-lightbox-enter-done .custom-lightbox-image {
  transform: scale(100%);
}

/** CSS TRANSITIONS FOR MODALS */

/* Based on https://github.com/Dogfalo/materialize/blob/v1-dev/js/modal.js */
.css-modal .modal,
.css-modal .modal-overlay {
  display: block;
}

.css-modal .modal {
  top: 10%;
}

.css-modal .modal-overlay {
  opacity: 0.5;
}

.css-modal-enter .modal,
.css-modal-enter .modal-overlay {
  transition: all .25s ease;
}

.css-modal-exit .modal,
.css-modal-exit .modal-overlay {
  transition: all .25s ease-out;
}

.css-modal-enter .modal,
.css-modal-exit.css-modal-exit-active .modal {
  opacity: 0;
  top: 4%;
  transform: scale(.8, .8);
}

.css-modal-enter .modal-overlay,
.css-modal-exit.css-modal-exit-active .modal-overlay {
  opacity: 0;
}

.css-modal-enter-done .modal,
.css-modal-enter.css-modal-enter-active .modal,
.css-modal-exit .modal {
  opacity: 1;
  transform: scale(1);
  top: 10%;
}

.css-modal-enter-done .modal-overlay,
.css-modal-enter.css-modal-enter-active .modal-overlay,
.css-modal-exit .modal-overlay {
  opacity: 0.5;
}

.css-modal .modal-overlay {
	position: fixed;
}

/** CHART MODAL */

.chart-modal {
  width: 80%;
  max-width: 1200px;
  height: 50%;
  max-height: 600px;
  padding: 24px;
}

/* TILFØJELSE TIL DATEPICKER */

.select-wrapper input.select-dropdown {
  height: 45px;
}

/* TILRETTELSE AF CARDS OG KNAPPER I SIDENAVRIGHT MINDRE END 601 PX */

@media only screen and (max-width: 600px) {
  .sidenav-right .card {
    margin-left: 0px !important;
  }

  .no-bottom-card {
    padding-bottom: 24px !important;
  }

  .sidenav-right-wrapper {
    margin-right: 8px;
  }
}

/* TILFØJELSE TIL CARDS */

.no-bottom-card {
  padding-bottom: 0 !important;
}

/** POPULARTIY CHART */

.popularity-chart .bb {
  height: 100%;
}

.popularity-chart .line-class-data2 {
  stroke-width: 4px;
}

.popularity-chart .domain {
  display: none;
}

.popularity-chart .bb-ygrid {
  stroke-width: 1px;
  stroke-dasharray: 0
}

.popularity-chart {
  font-family: Roboto, sans-serif;
  height: 100%;
  width: 100%;
}

.popularity-chart .bb-xgrid-lines text {
  fill: white;
  font-size: 14px;
}

.popularity-chart .bb-xgrid-lines line {
  stroke: white;
}

.popularity-chart .bb-xgrid-focus line {
  stroke: white;
  stroke-dasharray: 3;
}

.popularity-chart .tick {
  font-size: 12px;
  fill: #f5f5f5;
}

.popularity-chart .bb-axis-x .tick line {
  stroke: white;
  display: none;
}

.popularity-chart .bb-axis-x .tick:first-of-type tspan {
  text-anchor: start;
}

.popularity-chart .bb-axis-x .tick:last-of-type tspan {
  text-anchor: end;
}

.popularity-chart .bb-title text {}

.popularity-chart .bb-title {
  fill: white;
}

.popularity-chart .bb-title tspan:first-child {
  font-weight: 500;
  font-size: 22px;
  margin-left: 40px;
}

.popularity-chart .bb-region {
  fill-opacity: .1
}


/* HØJRE SLIDEOUT - TOP */

.iframe-header {
  max-width: 1200px;
  height: 64px;
  background-color: #fafafa;
  display: flex;
}

.close-button {
	flex-basis: 5rem;
}

.iframe-close {
  position: absolute;
  cursor: pointer;
  top: 6px;
  left: 28px;
}

.iframe-close i {
  font-size: 36px;
}

@media only screen and (max-width: 992px) {
  .iframe-header {
    display: none;
  }

  td .material-icons {
    line-height: .6;
    font-size: 20px;
  }
}

@media only screen and (min-width: 993px) {
  .no-sidebar .iframe-close {
    left: 12px;
  }
}


/* HØJRE SLIDEOUT  */

.sidenav-right-wrapper .row .col {
  padding: 0;
}

.sidenav-right-wrapper .row {
  margin-right: 0;
  margin-left: 0;
}

td,
th {
  padding: 15px 5px;
  font-size: 15px;
}

th {
  font-weight: 700;
}

.edit-icon {
  margin: 0px 12px 0px 0px;
  margin-top: 0px;
  margin-right: 12px;
  margin-bottom: 0px;
  margin-left: 0px;
}

.space12 {
  padding-left: 12px;
  padding-right: 12px;
}

#store-admin,
#user-admin,
#links-admin {
  display: none;
}

.sidenav-trigger + .sidenav-trigger {
  margin-left: 0;
}

.global-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: auto;
  z-index: 996;
}


/* NICK: Sidebar override. */

@media only screen and (min-width: 993px) {
  .sidenav.sidenav-fixed.sidenav-override-enter {
    transform: translate(-100%);
  }

  .sidenav.sidenav-fixed.sidenav-override-enter-active,
  .sidenav.sidenav-fixed.sidenav-override-enter-done {
    transform: translate(0);
  }

  .sidenav.sidenav-fixed.sidenav-override-exit {
    transform: translate(0);
  }

  .sidenav.sidenav-fixed.sidenav-override-exit-active,
  .sidenav.sidenav-fixed.sidenav-override-exit-done {
    transform: translate(-100%);
  }
}

@media only screen and (max-width: 992px) {
  #main.main--mobile-sidebar-visible .sidenav.sidenav-fixed.sidenav-override-enter {
    transform: translate(-100%);
  }

  #main.main--mobile-sidebar-visible .sidenav.sidenav-fixed.sidenav-override-enter-active,
  #main.main--mobile-sidebar-visible .sidenav.sidenav-fixed.sidenav-override-enter-done {
    transform: translate(0);
  }

  #main.main--mobile-sidebar-visible .sidenav.sidenav-fixed.sidenav-override-exit {
    transform: translate(0);
  }

  #main.main--mobile-sidebar-visible .sidenav.sidenav-fixed.sidenav-override-exit-active,
  #main.main--mobile-sidebar-visible .sidenav.sidenav-fixed.sidenav-override-exit-done {
    transform: translate(-100%);
  }
}

/* NICK: Content override. */
.content-override {
  position: fixed;
  top: 71px;
  left: 0;
  right: 0;
  bottom: 0;
  transition: transform 0.25s ease-out;
  padding-left: 8px;
  overflow-y: auto;
  z-index: 899;
}

.content-override-enter {
  transform: translate(100%);
}

.content-override-enter-active,
.content-override-enter-done {
  transform: translate(0);
}

.content-override-exit {
  transform: translate(0%);
}

.content-override-exit-active,
.content-override-exit-done {
  transform: translate(100%);
}

@media only screen and (min-width: 993px) {
  .content-override {
    left: 300px;
  }

  .main--desktop-sidebar-hidden .content-override {
    left: 0;
  }
}


/* HØJRE SIDEBAR */

.sidenav-right-wrapper {
  margin-right: 32px;
  max-width: 860px;
}

.sidenav-right-wrapper .section-headline:first-of-type {
  margin-top: 18px;
}

.sidenav-right-wrapper .card {
  margin-left: 24px;
  padding: 24px;
  min-height: 100px;
}


/* DIVERSE INPUT-FORMS - STANDARD */

.field-wrapper {
  padding-top: 16px;
  margin-bottom: 24px;
  clear: both;
}

.standardinput .input-field>label {
  left: 0;
  font-size: 18px;
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
}

.standardinput .input-field>label.active {
  left: 0;
  font-size: 18px;
  -webkit-transform: translateY(-6px);
  transform: translateY(-6px);
}

.standardinput .input-field>label:not(.label-icon).active {
  -webkit-transform: translateY(-28px) scale(0.65);
  transform: translateY(-28px) scale(0.65);
}

.standardinput input[type=text]:not(.browser-default),
.standardinput input[type=url]:not(.browser-default),
.standardinput select {
  font-size: 18px;
  margin: 0 0 0 0;
  height: 100%;
  padding-bottom: 5px;
  font-family: Roboto, sans-serif;
  color: #212121;
}

.standardinput select {
  display: block;
  appearance: none;
  border: 0;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  outline: none;
  border-bottom: 1px solid #9e9e9e;
  border-radius: 0;
}

.standardinput .caret {
  position: absolute;
  right: 0;
  top: 0;
}

.input-field {
  padding-left: 0px;
  margin-top: 8px;
}


/* DIVERSE INPUT-FORMS - AREAINPUT */

.bodytextinput textarea.materialize-textarea {
  background-color: transparent;
  border: 1px solid #9e9e9e;
  border-radius: 6px;
  min-height: 140px;
  padding: 28px;
}

.bodytextinput label:not(.label-icon).active {
  -webkit-transform: translateY(-26px) scale(0.65);
  transform: translateY(-26px) scale(0.65);
  background-color: white;
  padding-left: 4px;
  padding-right: 4px;
}

.bodytextinput .input-field>label {
  padding-top: 28px;
  left: 24px;
  font-size: 18px;
}

input[type=text]:not(.browser-default):disabled {
  border-bottom: 1px solid #9e9e9e;
}

/* ÆNDRING AF KNAP-STØRRELSE */

.btn-large {
  height: 36px;
  line-height: 36px;
}

/* MODALS */

.sidenav-right-wrapper .row .col.s12,
.modal .row .col.s12 {
  padding: 6px 0px !important;
}

.bottomrow {
  margin-bottom: 0px !important
}

/* NICK: FAB animations. */
/* Based on https://github.com/Dogfalo/materialize/blob/v1-dev/js/buttons.js */
@keyframes fixed-action-btn-open {
  0% {
    animation-timing-function: cubic-bezier(0.550, 0.085, 0.680, 0.530);
    overflow: visible;
    transform: translateX(calc(50vw - 28px - 23px));
  }

  67% {
    transform: none;
    overflow: hidden;
  }

  100% {
    transform: none;
    overflow: hidden;
  }
}

@keyframes fixed-action-btn-anchor-open {
  0% {
    transform: translateY(-23px);
    overflow: visible;
  }

  67% {
    transform: none;
    overflow: visible;
  }

  100% {
    transform: none;
    overflow: visible;
  }
}

@keyframes fixed-action-btn-backdrop-open {
  0% {
    transform: scale(0);
  }

  67% {
    transform: scale(0);
  }
}

@keyframes fixed-action-btn-link-open {
  0% {
    opacity: 0;
  }

  66% {
    opacity: 0;
  }

  67% {
    opacity: 1;
  }
}

.fixed-action-btn.active,
.fixed-action-btn.fixed-action-btn-exit {
  text-align: center;
  width: 100%;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.fixed-action-btn.active > a {
  overflow: visible;
}

.fixed-action-btn.active ul a {
  opacity: 1;
}

.fixed-action-btn.fixed-action-btn-enter {
  animation: fixed-action-btn-open;
  animation-duration: .3s;
}

.fixed-action-btn.fixed-action-btn-enter > a {
  animation: fixed-action-btn-anchor-open;
  animation-duration: .3s;
}

.fixed-action-btn.fixed-action-btn-enter .fab-backdrop {
  animation: fixed-action-btn-backdrop-open;
  animation-duration: .3s;
}

.fixed-action-btn.fixed-action-btn-enter ul a {
  animation: fixed-action-btn-link-open;
  animation-duration: .3s;
}

@keyframes fixed-action-btn-close {
  0% {
    transform: none;
    overflow: hidden;
  }

  33% {
    transform: none;
    overflow: visible;
    animation-timing-function: cubic-bezier(0.550, 0.085, 0.680, 0.530);
  }

  100% {
    overflow: visible;
    transform: translateX(calc(50vw - 28px - 23px));
  }
}

@keyframes fixed-action-btn-anchor-close {
  0% {
    transform: none;
    overflow: visible;
  }

  33% {
    transform: none;
    overflow: hidden;
    animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
  }

  100% {
    transform: translateY(-23px);
    overflow: hidden;
  }
}

.fixed-action-btn.fixed-action-btn-exit {
  animation: fixed-action-btn-close;
  animation-duration: .3s;
}

.fixed-action-btn.fixed-action-btn-exit > a {
  animation: fixed-action-btn-anchor-close;
  animation-duration: .3s;
}

.fixed-action-btn.fixed-action-btn-exit .fab-backdrop {
  animation: fixed-action-btn-backdrop-open;
  animation-duration: .3s;
  animation-direction: reverse;
}

.fixed-action-btn.fixed-action-btn-exit ul a {
  animation: fixed-action-btn-link-open;
  animation-duration: .3s;
  animation-direction: reverse;
}

/** ROTATION */

.turn90 {
  transform: rotate(90deg);
}

.turn180 {
  transform: rotate(180deg);
}

.turn270 {
  transform: rotate(270deg);
}

/* BUTIKSSKILTE */

.video-container {
  height: 100%;
  overflow:  visible;
}

.a4staaende {
  width: 210.1mm;
  height: 297.1mm;
}
.a4liggende {
  width: 297.1mm;
  height: 210.1mm;
}

.a3staaende {
  width: 297.1mm;
  height: 420.1mm;
}
.a3liggende {
  width: 420.1mm;
  height: 297.1mm;
}

.drag-handle {
  float: right;
  margin-top: 10px;
}

.top-fab {
  top: -20px;
}

#paper {
  padding: 0 !important;
}

/* HINTS */
.react-hint {
  padding: 5px;
  position: absolute;
  z-index: 9999;
  cursor: default;
  animation: .25s ease-out react-hint-in;
}

.react-hint__content {
  padding: 10px;
  border-radius: 2px;
  color: #fff;
}

.react-hint--top {
  transform: translateY(-10px);
}

.react-hint--left {
  transform: translateX(-10px);
}

.react-hint--right {
  transform: translateX(10px);
}

.react-hint--bottom {
  transform: translateY(10px);
}

@keyframes react-hint-in {
  from {
    opacity: 0;
    transform: none;
  }

  to {
    opacity: 1;
  }
}


/* MODALS */

.modal {
  /*	 padding-top:24px;
	 padding-bottom:16px;  */
  background-color: white;
  max-width: 800px;
}

.modal-headline {
  margin: 0;
  padding-bottom: 16px;
  color: #757575;
  font-size: 1rem;
}

.bottomrow {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px;
  margin-top: 24px;
}

.toprow {

  margin-bottom: 0px;

}

.bottomrow .col
{

  padding-left: 0px;

}

.sidenav .collapsible-body li a.menu-links,
.sidenav.fixed .collapsible-body li a.menu-links {
  margin-left: -31px;
  padding-right: 0;
}

.responsive-table td a {
    word-break: break-all;
}

.responsive-table th:nth-of-type(3) {
    min-width: 160px;
}


/* MESSAGE RECIPIENT SELECTOR. */

.message-recipient-selector .collapsible {
  margin: 0 !important;
  border: 0;
  box-shadow: none;
}

.message-recipient-selector .collapsible-body {
  border-bottom: 0;
  padding: 0 0 0 36px;
}

.message-recipient-selector .collapsible-header {
  padding: 6px 0;
  border-bottom: 0;
}

.message-recipient-selector .collapsible-header i {
  display: inline-block;
  font-size: 1.4rem !important;
  margin-right: .75rem;
  position: relative;
  text-align: center;
  top: 5px;
  width: 2rem;
}

.message-recipient-selector li.active > .collapsible-header i {
  transform: rotate(-90deg);
}


/* MESSAGES. */

.message:not(.message--inactive) .collapsible-header,
.message:not(.message--inactive) .collapsible-body {
  background-color: #fffde7;
}

.message:not(.active):not(.message--inactive) .collapsible-header:hover {
  background-color: #fff9c4;
}

.message:not(.active):not(.message--inactive) {
  background-color: #fff9c4;
}

.message .collapsible-body p:first-child {
  margin-top: 0;
}

.message .headline3 {
  color: rgba(0, 0, 0, 0.87);
}

.message .secondary-content {
  right: 32px
}

.message--inactive .collapsible-header,
.message--inactive .collapsible-body {
  background-color: #eee;
}

.message--inactive:not(.active) .collapsible-header:hover {
  background-color: #e0e0e0;
}

.message--inactive .headline2::after {
  color: red;
  margin-left: 16px;
  content: "IKKE AKTIV";
}

.message--inactive .action-more .standard-color:not(.right) {
  color: #bdbdbd;
}

.bottom-headline {
  color: #9e9e9e;
  margin-top: 1.5rem;
}

.message-status-selector .material-icons {
  margin-top: -6px;
  position: relative;
  top: 4px;
}

.message-list .card-panel {
  margin-bottom: 0;
}

.message-list .card {
  margin: 0;
}

.message-list .publications .collapsible {
  margin: 0;
}

.message-list--round-top {
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
}

.collapsible.title-messages {
  border-left: 0;
  border-right: 0;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: -16px;
  border-radius: 0px 0px 32px 32px;
}

.collapsible.news-and-reviews-list .message:last-child,
.collapsible.news-and-reviews-list .message:last-child:not(.active) .collapsible-header,
.collapsible.news-and-reviews-list .message.active:last-child .collapsible-body,
.collapsible.title-messages > li:last-child,
.collapsible.title-messages > li:last-child:not(.active) .collapsible-header,
.collapsible.title-messages > li.active:last-child .collapsible-body {
  border-radius: 0px 0px 32px 32px;
}

@media screen and (max-width: 600px) {
  .collapsible.title-messages {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.sidenav-right-wrapper .message-link-card,
.sidenav-right-wrapper .message-attachment-card {
  min-height: 200px;
}

.message--round-top {
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
}

.media-icon--publishing-house {
  background-color: #2d88b5;
}

.media-icon--bookseller {
  background-color: #349F7E;
}

.message.notification .collapsible-header .material-icons {
  color: black;
  line-height: 32px;
  font-size: 24px !important;
  margin-right: 24px;
}

.notification.message:hover .action-more,
.notification.message .collapsible-header:hover {
    background-color: #b2ff59  !important;
}

.notification.message .collapsible-header,
.notification.message .collapsible-body,
.notification.message:not(.active):not(.message--inactive), .notification .message:not(.active):not(.message--inactive) .collapsible-header:hover {
    background-color: #ccff90 ;
}

.notification .article-container {
	width: 100%;
}

.delete-quote {
	display: flex;
	align-items: center;
}
.delete-quote i {
	color: #d32f30;
}

/** Zoom-out animation for disappearing. */
@-webkit-keyframes zoomOut {
  from {
	opacity: 1;
  }
  50% {
	opacity: 0;
	-webkit-transform: scale3d(0.3, 0.3, 0.3);
	transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
	opacity: 0;
  }
}

@keyframes zoomOut {
  from {
	opacity: 1;
  }
  50% {
	opacity: 0;
	-webkit-transform: scale3d(0.3, 0.3, 0.3);
	transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
	opacity: 0;
  }
}

.zoom-out {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut;
}

.select-wrapper {
  display: inline-block;
}

.select-wrapper select {
  display: block;
}

.select-wrapper.standardinput select {
  padding-right: 22px;
}

.select-wrapper svg {
  pointer-events: none;
}

.add-new-button {
	display: flex;
	align-items: center;
	text-transform: none;
	margin-top: 1rem;
	margin-bottom: 2rem;
}

.add-new-button i {
	margin-right: 0.5rem;
}

.media-unknown {
    border: 1px solid #e0e0e0;
    color: #e0e0e0;
}

/* Rating styles */

.rating {
	display: block;
	border: 0;
	line-height: 1;
	height: 4rem;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	justify-content: flex-end;
	margin-top: 2rem;
}

.rating>input {
	display: none;
}

.rating>label {
	
	height: 4rem;
	display: block;
	color: #E0E0E0;
	transition: all 0.2s ease;
}

.rating>label:before {
	display: inline-block;
	font-size: 40px;
	margin: 0;
	cursor: not-allowed;
	font-family: 'Material Icons';
}

.rating.editable>label:before {
	cursor: pointer;
}

.stars-rating>label:before {
	content: 'grade';
	font-size: 48px;
}

.stars-rating.stars10>label:before {
	font-size: 32px;
}

.hearts-rating>label:before {
	content: 'favorite';
}

.rating .half:before {
	position: absolute;
	padding-right: 0;
	width: 20px;
	overflow: hidden;
}

.stars-rating .half:before {
	content: 'grade';
	width: 24px;
}

.stars-rating.stars10 .half:before {
	width: 16px;
}

.hearts-rating .half:before {
	content: 'favorite';
}

.hearts-rating input:checked~label,
.hearts-rating.editable label:hover,
.hearts-rating.editable label:hover~label {
	color: #D32F2F;
}

.stars-rating input:checked~label,
.stars-rating.editable label:hover,
.stars-rating.editable label:hover~label {
	color: #000;
}

.rating-disabled .rating {
	pointer-events: none;
}


.content-override .collapsible .collapsible-body .collapsible {
	border: none;
	box-shadow: none;
	padding: 0;
}

.content-override .collapsible .collapsible-body .collapsible-header {
	padding: 0px;
}

@media only screen and (max-width: 600px) {
	.sidenav-right-wrapper {
		margin-right: 8px;
	}
/*	.below-navigation {
		padding-right: 0
	} */
	.stars-rating.stars10>label:before {
		font-size: 28px;
	}
	.stars-rating.stars10 .half:before {
		width: 14px;
	}
}
/* grading ends */



/* Kasper's styles begin */
/* Give input section the right width */

.content-override .edit-content .reviews-list .collapsible-body {
	width: calc(100% - 186px);
}


/* Give input section the right width */

@media only screen and (max-width: 600px) {
	.content-override .edit-content .reviews-list .collapsible-body {
		width: 100%;
	}
}


/* Add/remove the main quote then collapsible is opened/closed */

.content-override .edit-content .reviews-list li:not(.active) .bog-review-quote {
	opacity: 1;
	transition: all 0.3s
}

.content-override .edit-content .reviews-list li.active .bog-review-quote {
	opacity: 0;
	height: 0;
	transition: all 0.3s
}

.content-override .edit-content .reviews-list li.active .collapsible-header {
	padding-bottom: 0;
	transition: all 0.3s;
}


/* Needed to give buttons the right size */

 
/* Give quote-icon correct placement */

.content-override .edit-content .bog-review-edit-quote:after {
	left: 15px;
	z-index: 1;
	top: 46px;
}
.content-override .edit-content .bog-review-edit-quote:first-of-type:after {
	left: 15px;
	z-index: 1;
	top: 75px;
}


/* Change cursor on diableded textarea */

.content-override .edit-content .materialize-textarea:disabled {
	cursor: not-allowed;
}


/* Give textarea the right look */

.content-override .edit-content .bog-review-edit-quote .input-field>label:not(.active),
.content-override .edit-content textarea.materialize-textarea {
	padding: 28px;
	font-size: 16px;
	font-weight: 400;
}

.content-override .edit-content .bog-review-edit-quote textarea.materialize-textarea {
	font-size: 22px;
	padding-top: 38px;
	font-weight: 500;
}
	

.content-override .edit-content textarea.materialize-textarea {
	color: #000;
}


/* Remove space above rating scale */

.content-override .edit-content .rating {
	margin-top: 0
}

.content-override .edit-content .reviews-list .grading-scales .collapsible-body {
	padding-left: 0;
	padding-top: 12px;
}


/* Give tabs a max-width */

.content-override .edit-content .tabs {
	padding-left: 0;
	max-width: 812px;
	margin: 0;
}


/* Give cards in other tabs the same spece above */

.content-override .edit-news .card:first-of-type {
	margin-top: 52px;
}


/* -------------------------------------------- */

/* Make a little space before first input */

.content-override .edit-content .reviews-list .collapsible-header {
	min-height: 100px;
}


/* Remove the last media option for now */

.modal-content .step-title:first-of-type .col.s6:last-of-type {
	display: none;
}

/* Less space above first input field */

.content-override .edit-content .collapsible-body .lg-margin-top:first-of-type {
	margin-top: 0;
}

/* Adjust quote-label */

.content-override .edit-content .bog-review-edit-quote .input-field>label:not(.active) {
	font-size: 22px;
	padding-top: 38px;
	font-weight: 500;
}


/* Remove date-ivon */

.content-override .edit-content .date-picker-container .material-icons {
	display: none;
}


.content-override .edit-content .review-date-picker {
margin-top: 40px;
}

/* Add label to dates */

.content-override .edit-content .date-picker-container:before {
	content: "Dato";
	position: absolute;
	transform: translateY(-28px) scale(.65);
	left: 18px;
	color: rgb(45, 136, 181)
}


/* Because <div class="lg-margin-top"> does not surround the date field on older reviews ... */
.content-override .edit-content .add-new-button + form {
	margin-top: 2rem;
}
 
	
.content-override .tabs {
	padding-left: 0;
	max-width: 812px;
	margin: 0;
}
.content-override .edit-content .delete-quote i,.content-override .edit-content .add-new-button i {margin-right: 15px;font-size: 1.3rem;}

@media only screen and (max-width: 992px) {
	.content-override .iframe-header {
		display: flex;
	}
	.content-override .close-button {
		display: none;
	}
}
	
	
/* Kasper's styles end */

.review-date-picker .date-picker-container {
	margin: 1rem 0;
	border-bottom: 1px solid #9e9e9e;
	font-size: 18px;
    padding-bottom: 5px;
    font-family: Roboto, sans-serif;
 
}
.review-date-picker .react-datepicker__input-container a.standard-color {
	color: #212121;
	display: flex;
	align-items: center;
}
.add-new-reviewer-modal .modal {
	overflow-y: visible;
}
.modal-content {
    counter-reset: section;
}

.bottomrow {
    margin: 24px auto 0;
}

.step-content {
    margin-left: 32px;
    line-height: 40px;
    margin-bottom: 0;
}
.step-title p {
    font-weight: 500;
    margin: 0 -24px;
    cursor: pointer;
    padding: 15.5px 44px 24px 64px;
    display: block;
    position: relative;
}

.step-title p::before {
    position: absolute;
    counter-increment: section;
    content: counter(section);
    height: 26px;
    width: 26px;
    color: white;
    background-color: #B2B2B2;
    border-radius: 50%;
    text-align: center;
    line-height: 26px;
    font-weight: 400;
    font-size: 14px;
    left: 24px;
    top: 13px;
}

.step-title.filled p::before {
    background-color: #2d88b5;
}

.step-title.error p {
    color: red;
}

.step-title.error p::before {
    background-color: red;
}

.text-bold {
	font-weight: bold;
}

input:disabled {
	color: rgba(0,0,0,0.42);
	cursor: not-allowed;
}

.sm-margin-top {
	margin-top: 1rem;
}

.sm-margin-bottom {
	margin-bottom: 1rem;
}

.sm-margin-left {
	margin-left: 1rem;
}

.sm-margin-right {
	margin-right: 1rem;
}

.md-margin-top {
	margin-top: 1.5rem;
}

.md-margin-bottom {
	margin-bottom: 1.5rem;
}

.md-margin-left {
	margin-left: 1.5rem;
}

.md-margin-right {
	margin-right: 1.5rem;
}

.lg-margin-top {
	margin-top: 2rem;
}

.lg-margin-bottom {
	margin-bottom: 2rem;
}

.lg-margin-left {
	margin-left: 2rem;
}

.lg-margin-right {
	margin-right: 2rem;
}
